export default {
  setPedidos(state, value) {
    state.pedidos = value;
  },

  setVolumes(state, value) {
    state.volumes = value;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setList(state, value) {
    state.list = value;
  },

  setLinha1(state, value) {
    state.linha1 = value;
  },

  setLinha2(state, value) {
    state.linha2 = value;
  },

  setLinha3(state, value) {
    state.linha3 = value;
  },

  setLinha4(state, value) {
    state.linha4 = value;
  },

  setHistoricoDoPedido(state, value) {
    state.historicoDoPedido = value;
  },

  setDetalhesDoPedido(state, value) {
    state.detalhesDoPedido = value;
  },

  setLinhasProducao(state, value) {
    state.linhasProducao = value;
  },

  setTotalItems(state, value) {
    state.total_items = value;
  },

  setUrlParams(state, value) {
    state.urlParams = value;
  },

  setIsLoadingZerarLinha(state, value) {
    state.isLoadingZerarLinha = value;
  },
};
