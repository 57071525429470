export default {
  getPedidos: (state) => state.pedidos,

  getVolumes: (state) => state.volumes,

  getIsLoading: (state) => state.isLoading,

  getList: (state) => state.list,

  getLinha1: (state) => state.linha1,

  getLinha2: (state) => state.linha2,

  getLinha3: (state) => state.linha3,

  getLinha4: (state) => state.linha4,

  getHistoricoDoPedido: (state) => state.historicoDoPedido,

  getDetalhesDoPedido: (state) => state.detalhesDoPedido,

  getLinhasProducao: (state) => state.linhasProducao,

  getTotalItems: (state) => state.total_items,

  getUrlParams: (state) => state.urlParams,

  getIsLoadingZerarLinha: (state) => state.isLoadingZerarLinha,
};
